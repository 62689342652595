@import '../../../variables';

.container {
  width: 40px;
  height: 40px;
  background-color: $green;
  border-radius: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.check {
  width: 24px;
  height: 24px;
}
