@import '../../../../variables';

.tv-table__header {
  font-size: 0.8em;
  background-color: $gray-bg-1;
  border-top-width: 1px;
  border-top-style: solid;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: $gray-border-3;
}

.tv-table__row {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: $gray-border-3;
}

.fund-selections-container {
  background-color: $blue-bg-2;
  border-radius: 8px;
}

.fund-selections-current-fund {
  flex-basis: 40%;
  flex-shrink: 0;
}

.fund-selection-divider {
  width: calc(100% - 2rem);
  border-bottom: 1px solid $blue-border-3;
}
