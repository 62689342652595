@mixin shimmerWithColor($shimmer-color: #f5f7f9, $shimmer-color2: #fff) {
  display: block;
  width: 100%;
  background: linear-gradient(to right, $shimmer-color 30%, $shimmer-color2 50%, $shimmer-color 70%)
    no-repeat;
  background-size: 300% 100%;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer-animation;
  animation-timing-function: linear;
}

@keyframes shimmer-animation {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.shimmerDefault {
  @include shimmerWithColor;
}
