@import '../../variables';

.definitions {
  margin: 0;
  display: flex;
  flex-direction: row;

  dt {
    font-size: $font-size-small;
  }

  dt,
  dd {
    margin: 0;
  }

  @include mobile {
    flex-direction: column;
  }
}

.group {
  margin-left: 18px;
  flex-grow: 1;

  &:first-child {
    margin-left: 0px;
  }

  @include mobile {
    margin-top: 18px;
    margin-left: 0px;

    &:first-child {
      margin-top: 0px;
    }
  }
}

.innerGroup {
  display: flex;
  flex-direction: row;
  margin-top: 18px;

  &:first-child {
    margin-top: 0px;
  }
}

.rightGroup {
  margin-left: auto;
  text-align: right;
}
