@import '../../variables';
@import '~bootstrap/scss/bootstrap.scss';

.table-container {
  width: 100%;
}
.table td:not(:first-child),
.table th:not(:first-child) {
  text-align: right;
}
.table thead td,
.table thead th {
  font-weight: $font-weight-medium;
}
.table tr > :first-child {
  padding-left: 0;
}
.table tr > :last-child {
  padding-right: 0;
}
.table tfoot td:first-child,
.table tfoot td:last-child,
.table tfoot th:first-child,
.table tfoot th:last-child {
  font-weight: $font-weight-bold;
}

@media (min-width: 992px) {
  .table thead td,
  .table thead th {
    white-space: nowrap;
  }
}

/* test without previous custom styles

.table {
  width: auto !important;
  max-width: none !important;
  margin-bottom: 15px;
}

.table th {
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 0.8125em;
  background-color: $gray-bg-1;
  border-top-width: 1px;
  border-top-style: solid;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: $gray-border-3;
  color: $text-body-secondary;
  @include not-mobile {
    white-space: nowrap;
  }
}

.table tbody {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-color: $gray-border-3;
}

.table tfoot td:last-child,
.table tfoot td:first-child {
  font-weight: bold;
}

.table td,
.table th {
  text-align: right;
}

.table td:first-child,
.table th:first-child {
  text-align: left;
}
*/
