@import '../../variables';

.tv-radio {
  background-color: $gray-bg-1;
  border-width: 1px;
  border-style: solid;
  border-color: $gray-border-3;
  border-radius: 8px;
  padding: 1.5rem;
  width: 100%;

  transition: background-color 0.15s ease-out, border-color 0.15s ease-out;

  &:not(&--selected):not(&--disabled),
  &:not(&--selected):not(&--disabled) > * {
    cursor: pointer;
  }

  & .row {
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
  }

  &--selected {
    background-color: $blue-bg-2;
    border-color: $blue;
    border-width: 2px;
    cursor: auto;

    & .row {
      border-width: 0;
    }

    & .tv-radio__button {
      border-color: $blue;

      & .tv-radio__check {
        transition: transform 0.15s ease-out;
        background-color: $blue;
        transform: scale(1);
      }
    }
  }

  &__inline {
    border: 0;
    background: transparent;
    padding: 0;
    line-height: 2rem;
    user-select: none;

    & .row {
      border-width: 0;
    }

    &.tv-radio--selected {
      font-weight: $font-weight-semibold;
    }

    &.tv-radio--disabled {
      color: $text-body-secondary;
    }
  }

  &.tv-radio--disabled {
    .tv-radio__button {
      cursor: default;
      border-color: $text-tertiary;
    }
  }

  &__button {
    border-radius: 50%;
    cursor: pointer;
    border-color: rgba(48, 48, 48, 0.64);
    padding: 3px 3px;
    width: 20px;
    height: 20px;
    border-style: solid;
    display: inline-block;
    background-color: transparent;
    border-width: 2px;
    transition: border-color 0.15s ease-out;
  }

  &__check {
    transition: transform 0.15s ease-out;
    width: 10px;
    height: 10px;
    display: block;
    transform: scale(0);
    border-radius: 50%;
  }
}
