@import '../../../variables';

.tv-step__number {
  user-select: none;
}
.tv-step__number {
  width: 32px;
  height: 32px;
  color: $blue;
  display: inline-block;
  border-color: $gray-border-3;
  border-width: 1px;
  line-height: 30px; // minus border
  border-style: solid;
  border-radius: 50%;
  text-align: center;
  background-color: white;
  z-index: 3;
  flex-basis: 32px;
  flex-shrink: 0;
}

.tv-step__title {
  display: flex;
  align-items: center;

  &--completed {
    & .tv-step__number {
      vertical-align: middle;
      background: white url('done.svg') center center no-repeat;
      background-size: 24px;
    }
  }

  &--active {
    & .tv-step__number {
      color: $blue;
      background-color: rgba(5, 147, 252, 0.24);
      border-color: transparent;
    }
  }

  &--previous {
    & .tv-step__number {
      color: $blue;
      background-color: rgba(5, 147, 252, 0.24);
      border-color: transparent;
      svg {
        vertical-align: top;
        margin-top: 9px;
      }
    }
  }

  &--current {
    & .tv-step__number {
      color: var(--White, #fff);
      font-weight: 500;
      background-color: $blue;
      border-color: transparent;
    }
  }

  &--upcoming {
    color: #6c757d;
    & .tv-step__number {
      color: #6c757d;
      background-color: white;
    }
  }

  & > span.h2 {
    vertical-align: middle;
  }
}
