@import '../../variables';

.second-pillar-upsell .badge-orange {
  background: rgba(255, 72, 0, 0.2);
  color: $orange;
  font-weight: normal;
  padding: 0.5em 0.8em;
  vertical-align: text-bottom;
}

.small-bullet-list {
  list-style-type: '\2022   ';
}

.lh-base {
  line-height: 1.5 !important;
}
