.full-screen {
  width: 100%;
  height: 100vh;
}

.centered-contents {
  display: flex;
  align-items: center;
  justify-content: center;
}
