/**
 * Roboto VF
 * https://github.com/googlefonts/roboto-3-classic
 */

@font-face {
  font-family: 'Roboto';
  src: url('./Roboto[ital,wdth,wght].ttf') format('truetype-variations');
  font-weight: 100 900; /* Enables the weight axis from 100 to 900 */
  font-stretch: 75% 125%; /* Enables the width axis from 75% to 125% */
  font-style: normal; /* For non-italic styles */
  font-display: fallback;
}

@font-face {
  font-family: 'Roboto';
  src: url('./Roboto[ital,wdth,wght].ttf') format('truetype-variations');
  font-weight: 100 900;
  font-stretch: 75% 125%;
  font-style: italic; /* For italic styles */
  font-display: fallback;
}
