@import '../../variables';

.tv-step {
  position: relative;
}

.tv-steps > .tv-step:not(:first-child)::before {
  content: '';
  background-color: $gray-border-3;
  display: inline-block;
  position: absolute;
  height: 25px;
  width: 1px;
  z-index: -1;
  left: 16px;
  top: -24px;
}
