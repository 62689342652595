/**
 * Here we lay out base styles, like typography and bootstrap customization.
 */

@import './fonts/index.scss';
@import 'variables';
@import '~bootstrap/scss/bootstrap.scss';
@import '~font-awesome/scss/font-awesome.scss';

/**
 * Element overrides
 */

html {
  min-width: 320px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

/* typography */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: $font-weight-medium;
  text-wrap: pretty;
}
h1,
.h1 {
  font-size: $h1-font-size;
  line-height: $h1-line-height;
  font-weight: $font-weight-semibold;
}
h2,
.h2 {
  font-size: $h2-font-size;
  line-height: $h2-line-height;
}
h3,
.h3 {
  font-size: $h3-font-size;
  line-height: $h3-line-height;
}
h4,
.h4 {
  font-size: $h4-font-size;
  line-height: $h4-line-height;
}
h5,
.h5 {
  font-size: $h5-font-size;
  line-height: $h5-line-height;
}
h6,
.h6 {
  font-size: $h6-font-size;
  line-height: $h6-line-height;
}

.lead {
  font-size: 1.25em;
  font-weight: $font-weight-normal;
}

small {
  font-size: $font-size-small;
  color: $text-primary;
}

.form-control::placeholder {
  color: $text-tertiary;
}

.btn-light {
  color: $blue;
  border: 1px solid $gray-border-3;
  background: white;
}

.brand-logo {
  max-width: 146px;
}

.app-header {
  .brand-logo {
    width: auto;
    height: 60px;
  }

  @media (max-width: 576px) {
    .brand-logo {
      height: 52px;
    }
  }
  @media (max-width: 375px) {
    /* iPhone 6 and newer */
    .brand-logo {
      height: 48px;
    }
  }
  @media (max-width: 359px) {
    .brand-logo {
      height: 42px;
    }
  }
}

.control-code {
  font-size: 5em;
  font-weight: bold;
}

hr {
  margin: 0;
  padding: 0;
  border-color: $gray-border-3;
  background-color: white;
  opacity: 1;
}

.custom-select {
  width: 100%;
}

.form-label {
  font-weight: $font-weight-semibold;
}

.alert-success-notice {
  background-color: rgba(11, 250, 67, 0.12);
}

.card {
  border-radius: 8px;

  &-primary {
    border-color: $blue-light;
    background-color: $blue-bg-2;
  }

  &-header {
    &-collapsed {
      border-bottom-width: 0;
    }
    & .form-check {
      padding-left: 54px !important; // 24px + 30px
    }
    & .form-switch {
      padding-left: 74px !important; // 24px + 50px
    }
  }
}

.form-check-label {
  cursor: pointer;
}

.ff-system {
  font-family: $font-family-system;
}

.text-danger {
  color: $red !important;
}
.text-success {
  color: $text-green !important;
}
.text-body-secondary {
  color: $text-body-secondary !important;
}
.text-body-tertiary {
  color: $text-body-tertiary !important;
}
.text-navy {
  color: $navy-blue !important;
}

.text-separator {
  color: $text-body-secondary !important;
  user-select: none;
}

.text-balance {
  text-wrap: balance;
}

// Backgrounds

.bg-gray-1 {
  background-color: $gray-bg-1;
}
.bg-gray-2 {
  background-color: $gray-bg-2;
}

.bg-blue-1 {
  background-color: $blue-bg-1;
}
.bg-blue-2 {
  background-color: $blue-bg-2;
}

/* layout */

.app-footer a {
  color: inherit;
  text-decoration-color: currentcolor !important;
}

/* Bootstrap 5 Migration */

a:not(.btn),
.btn.btn-link {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
    text-decoration-color: rgba($link-hover-color, var(--bs-link-underline-opacity, 1));
    text-decoration-thickness: 0.0625em;
    text-underline-offset: 0.125em;
    --bs-link-underline-opacity: 0.75;
  }
}
