@import '../variables';

.login-page {
  background: $gray-bg-2;
  min-height: 100vh;
  &__not-member {
    font-weight: 500;
    color: $text-primary;
    font-size: 1.3em;
  }
  &__apply {
    text-decoration: underline;
  }
}

.fb-widget {
  overflow: hidden;
}

.tab-list {
  border-bottom: 1px solid $gray-border-3;
  padding-left: 0;
}

.tab-list-item {
  width: 33.3333%;
  max-width: 100%;
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  color: $text-body-secondary;
  background: #fff;
  box-shadow: none;
  border: none;
  text-shadow: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@include mobile {
  .tab-list-item {
    width: 50%;
  }
}

.tab-list-item:focus {
  outline: none !important;
  outline-offset: none !important;
}

.tab-list-active {
  font-weight: bold;
  color: $text-primary;
  border-bottom: 4px solid $blue;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
