@import '../../variables';

$padding: 15px;

.card {
  width: auto !important;
  max-width: none !important;
  margin-bottom: $padding;
  border: 1px solid $gray-border-3;
  border-radius: 0.25rem;
  overflow: hidden;
}

.header {
  padding: $padding 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $gray-border-3;
}

.content {
  background-color: $gray-bg-1;
  padding: $padding 18px;
}

.footer {
  padding: $padding 18px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid $gray-border-3;
}
