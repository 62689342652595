@import '../../variables';

.stepDivider {
  width: 24px;
  height: 1px;
  background: $gray-border-3;
  margin: 0 8px;
}
@media (max-width: 767.98px) {
  .stepDivider {
    width: 1px;
    height: 16px;
    margin: 4px 15.5px;
  }
}

.successText {
  color: $text-green;
}

.warningText {
  color: $red;
}

.mandateSubheading {
  color: $blue;
  text-transform: uppercase;
  font-size: $font-size-small;
}

.mandateHeading {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
}

.pillarSelectionContainer {
  font-size: 1.25rem;

  .tv-radio {
    line-height: 2rem;
  }
}

.pillarSelectionContainerWarning {
  background-color: $note-bg;
  padding: 1em 1.25em;
  font-size: 0.75em;
  text-align: center;
  border-bottom: 1px solid $gray-border-3;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.singleWithdrawalAmountInputContainer {
  min-width: 10em;
}
@media (max-width: 767.98px) {
  .singleWithdrawalAmountInputContainer {
    width: 100% !important;
  }
}

.doneAlert {
  background: $blue-bg-2;
  border-radius: 16px;
  padding: 1.75em;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $navy-blue;
  text-align: center;

  h3 {
    color: $navy-blue;
  }
}
