.tv-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(209, 213, 219, 0.5);
  z-index: 999;

  &__content {
    background-color: #fff;
  }
}
