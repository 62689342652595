@import '../../variables';

.comparison-calculator {
  .card-primary {
    border-color: $blue-border-3;
  }

  .header-section {
    border-bottom: 1px solid $blue-border-3;

    .btn-group .btn {
      min-width: 200px;
    }

    .pillar-selection {
      + .input-selection > div {
        margin-top: 1rem;
      }
    }

    .input-selection {
      margin-left: -12px;
      margin-right: -12px;

      > div {
        padding-left: 12px;
        padding-right: 12px;
      }
    }

    //To scale header buttons on smaller screens
    @media (max-width: 768px) {
      .btn-group {
        width: 100%;
        margin-left: 15px;
        margin-right: 15px;
      }
      .btn-group .btn {
        width: 50%;
        min-width: auto;
        padding-left: 7px;
        padding-right: 7px;
      }
    }
  }

  .footer-section {
    border-top: 1px solid $blue-border-3;
  }

  .middle-section,
  .middle-section p {
    color: $navy-blue;
  }

  .middle-section {
    min-height: 333px;

    > .alert:first-child {
      margin-top: -1px !important;
    }

    .content-section {
      .result-section {
        flex-grow: 1;

        .result-positive {
          color: $text-green;
          font-weight: bold;
        }

        .result-text {
          // From Figma
          @media (max-width: 768px) {
            font-size: 16px;
          }

          margin: 0;
          font-size: 20px;
          line-height: 140%; /* 28px */

          + .result-text {
            margin-top: 1.5rem;
          }
        }

        .result-action {
          margin: 2rem 0 0;

          + .result-action-explainer {
            margin-top: 1em !important;
          }
        }

        @media (max-width: 768px) {
          .btn {
            //Restore default padding on smaller screens
            padding-left: 0.75rem;
            padding-right: 0.75rem;
          }
        }
      }

      .graph-section {
        padding-top: 42px;
        padding-bottom: 42px;
        min-height: 285px;

        .bar-container {
          display: flex;
          align-items: flex-end;
          justify-content: space-around;
        }

        .bar {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .bar-amount {
          position: absolute;

          //From Figma

          text-align: center;
          font-size: 16px;
          font-weight: 700;
          line-height: 1.5;
        }

        .bar-graph {
          height: 1px;
          width: 100%;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .bar-percentage {
          //From Figma
          color: #fff;
          text-align: center;
          font-size: 24px;
          font-weight: 700;
          line-height: 1; /* 24px */
        }

        .bar-label {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.2857; /* 18px */
        }

        .bottom-divider {
          height: 1px;
          background: linear-gradient(
            to right,
            rgba(0, 47, 99, 0) 0%,
            rgba(0, 47, 99, 1) 24px,
            rgba(0, 47, 99, 1) calc(100% - 24px),
            rgba(0, 47, 99, 0) 100%
          );
        }
      }
    }
  }
}
