@import '../../variables';

.customRangePayout {
  -webkit-appearance: none;
  appearance: none;
  display: block;
  margin: 0 auto;
  height: 12px;
  border-radius: 12px;
  outline: none;
  background: $gray-border-2;
}
.customRangePayout::-webkit-slider-runnable-track {
  border: none;
  background: transparent;
}
.customRangePayout::-moz-range-track {
  border: none;
  background: transparent;
}
.customRangePayout::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 50%;
  background: #fff;
  cursor: col-resize;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25), 0 1px 2px 0 rgba(0, 0, 0, 0.25);
}
.customRangePayout::-moz-range-thumb {
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 50%;
  background: #fff;
  cursor: col-resize;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25), 0 1px 2px 0 rgba(0, 0, 0, 0.25);
}
