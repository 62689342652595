/**
 * Responsive breakpoints
 */

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 960px,
  // override for xl breakpoint
  xxl: 960px // override for xxl breakpoint,,
);

/**
 * Fonts customization
 */

$font-family-sans-serif: 'Roboto', 'Segoe UI', 'Helvetica Neue', Arial, sans-serif !default;
$font-family-serif: 'Merriweather', Georgia, 'Times New Roman', Times, serif !default;
$font-family-system: system-ui, sans-serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-small: 0.875em; // 14

$h1-font-size: 1.75rem !default; // 32
$h2-font-size: 1.5rem !default; // 24
$h3-font-size: 1.25rem !default; // 20
$h4-font-size: 1.125rem !default; // 18
$h5-font-size: 1.125rem !default; // 18
$h6-font-size: 1.125rem !default; // 18

$h1-line-height: 1.2857; // 36
$h2-line-height: 1.3333; // 32
$h3-line-height: 1.4; // 28
$h4-line-height: 1.3333; // 24
$h5-line-height: 1.3333; // 24
$h6-line-height: 1.3333; // 24

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;
$font-weight-base: $font-weight-normal;

/**
 * Colors
 */

$text-primary: #293036;
$text-secondary: #6d7276;
$text-tertiary: #909396;

$form-check-input-border: 1px solid $text-tertiary;

$text-body-primary: $text-primary;
$text-body-secondary: $text-secondary;
$text-body-tertiary: $text-tertiary;

$table-color: $text-primary;

$blue: #0072ec;
$navy-blue: #002f63;
$navy-blue-secondary: #5c738c;
$blue-light: #00aeea;
$orange: #ff4800;
$yellow: #fce228;
$green: #51c26c;
$text-green: #00ac00;
$red: $orange;

$link-color: $blue;
$link-hover-color: $blue;

$note-bg: #fff6d9;
$note-border: #faeed0;

$gray-bg-1: #fafbfc;
$gray-bg-2: #f5f7f9;
$gray-border-1: #ebeef3;
$gray-border-2: #e0e6ec;
$gray-border-3: #d6dee6;

$blue-bg-1: #f3fafe;
$blue-bg-2: #eef7fd;
$blue-border-1: #ddeefa;
$blue-border-2: #d3e6f4;
$blue-border-3: #cadeee;

$border-color: $gray-border-3;
$card-border-color: $gray-border-3;

:root {
  --blue: #{$blue};
  --red: #{$red};
  --green: #{$green};
}

/**
 * Buttons
 */

$btn-font-weight: 450;
$btn-padding-x: 1rem;

/**
 * Brand definitions
 */

$brand-primary: $blue !default;
$brand-success: $green !default;
$body-color: $text-primary !default;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs: 480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
$screen-lg: 1224px !default;
$screen-lg-min: $screen-lg !default;
$screen-lg-desktop: $screen-lg-min !default;

// Extra large screen / extra wide desktop
$screen-xl: 1600px !default;
$screen-xl-min: $screen-xl !default;
$screen-xl-desktop: $screen-xl-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
$screen-lg-max: ($screen-xl-min - 1) !default;

// TODO: put someplace else
@mixin mobile {
  // mobile < 768px
  @media (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin not-mobile {
  // not-mobile > 768px
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

$fa-font-path: '~font-awesome/fonts';

$form-range-thumb-width: 1.5rem !default;
$form-range-thumb-border-radius: 100% !default;

//$transition-collapse:         height .35s ease !default;
//$transition-collapse-width:   width .35s ease !default;
