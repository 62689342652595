@import '../../../variables';

.btn-payment {
  min-width: 144px;

  &.active {
    font-weight: 500;
  }
}

.btn.btn-swedbank,
.btn.btn-swedbank:hover {
  background-color: white;
  border-color: #fb4f00;
  color: #fb4f00;
}

.btn.btn-swedbank:not(:disabled):not(.disabled):active,
.btn.btn-swedbank:not(:disabled):not(.disabled).active {
  background-color: #fb4f00;
  border-color: #fb4f00;
  color: white;
}

.btn.btn-seb,
.btn.btn-seb:hover {
  background-color: white;
  border-color: #45b400;
  color: #45b400;
}

.btn.btn-seb:not(:disabled):not(.disabled):active,
.btn.btn-seb:not(:disabled):not(.disabled).active {
  background-color: #45b400;
  border-color: #45b400;
  color: white;
}

.btn.btn-lhv,
.btn.btn-lhv:hover {
  background-color: white;
  border-color: #4a4e5a;
  color: #4a4e5a;
}

.btn.btn-lhv:not(:disabled):not(.disabled):active,
.btn.btn-lhv:not(:disabled):not(.disabled).active {
  background-color: #4a4e5a;
  border-color: #4a4e5a;
  color: white;
}

.btn.btn-luminor,
.btn.btn-luminor:hover {
  background-color: white;
  border-color: #481335;
  color: #481335;
}

.btn.btn-luminor:not(:disabled):not(.disabled):active,
.btn.btn-luminor:not(:disabled):not(.disabled).active {
  background-color: #481335;
  border-color: #481335;
  color: white;
}

.btn.btn-coop,
.btn.btn-coop:hover {
  background-color: white;
  border-color: #0078d2;
  color: #0078d2;
}

.btn.btn-coop:not(:disabled):not(.disabled):active,
.btn.btn-coop:not(:disabled):not(.disabled).active {
  background-color: #0078d2;
  border-color: #0078d2;
  color: white;
}

.btn.btn-other:not(:disabled):not(.disabled):active,
.btn.btn-other:not(:disabled):not(.disabled).active {
  background-color: $blue;
  border-color: $blue;
  color: white;
}

.payment-details {
  border: 1px solid $gray-border-3;
  border-radius: 8px;
}

.payment-details-table {
  background-color: $blue-bg-2;
  border-radius: 4px;
  display: table;

  table {
    tr td {
      padding: 0.75rem;
      border-bottom: 1px solid $gray-border-3;
    }

    tr:last-child td {
      border-bottom: none;
    }
  }
}
